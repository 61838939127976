import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { provideTranslocoLocale, TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { isDevMode, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { LocalstorageKey } from '@tcc-mono/shared/ui/theming';

import { availableLangs, TranslocoHttpLoader } from '@tcc-mono/shared/utils'

@NgModule({
  imports: [
    TranslocoLocaleModule
  ],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: availableLangs,
        defaultLang: localStorage.getItem(environment.appCode) ? JSON.parse(localStorage.getItem(environment.appCode))[LocalstorageKey.Language] : 'nl',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-GB',
        nl: 'nl-NL',
      },
    })
  ]
})
export class TranslocoRootModule { }
