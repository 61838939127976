import { NgModule } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@tcc-mono/shared/utils';
import { AreYouSureService } from './are-you-sure.service';

@NgModule({
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'sharedUi',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../${root}/${lang}.json`)
        )
      }
    },
    AreYouSureService
  ]
})
export class SharedUiServiceModule {

}
