import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { map, Observable, tap } from 'rxjs';

export const AuthGuard = (): Observable<boolean> => {
  const authService = inject(AuthenticationService);

  return authService.me$
    .pipe(
      map((me) => !!me),
      tap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          authService.login$();
        }
      })
    );
}
