import { inject, Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  readonly _appConfig = inject(APP_CONFIG);

  public readonly LOCAL_STORAGE_KEY = this._appConfig.appCode + '-auth';

  private get _localStorageData(): { [key: string]: unknown } | null {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
  }

  get<T = unknown>(key: string): T {
    return this._localStorageData && (this._localStorageData[key] as T);
  }

  set<T = unknown>({ key, value }: { key: string; value: T }): void {
    const updatedLocalStorageData = {
      ...(this._localStorageData ?? {}),
      [key]: value
    };
    localStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify(updatedLocalStorageData)
    );
  }

  clear(key: string): void {
    const { [key]: removedProp, ...remainingLocalStorageData } =
      this._localStorageData ?? {};
    localStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify(remainingLocalStorageData)
    );
  }
}
