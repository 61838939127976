import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AuthenticationModule } from '@tcc-mono/shared/authentication/passport';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicRouteStrategy } from '@ionic/angular';
import { AnimationService, FontawesomeTypeService, LanguageService } from '@tcc-mono/shared/ui/theming';
import { ClassifactMobileRoutingModule } from './classifact-mobile-routing.module';
import nl from '@angular/common/locales/nl';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

registerLocaleData(nl);

@NgModule({
  imports: [
    BrowserModule,
    AuthenticationModule.forRoot(),
    HttpClientModule,
    FontAwesomeModule,
    ClassifactMobileRoutingModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    InAppBrowser,
    Media,
    AndroidPermissions,
    Diagnostic
  ]
})
export class ClassifactMobileModule {
  constructor(
    private _animationService: AnimationService,
    private _fontawesomeService: FontawesomeTypeService,
    private _languageService: LanguageService
  ) {
    this._animationService.initTheme();
    this._fontawesomeService.initTheme();
    this._languageService.initLang();
  }
}
