import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core.module';
import { ClassifactMobileModule } from '@tcc-mono/classifact/mobile/shell/feature';
import { TranslocoRootModule } from './transloco-root.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ClassifactMobileModule,
    TranslocoRootModule,
    IonicModule.forRoot()
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
