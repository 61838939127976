import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  readonly _appConfig = inject(APP_CONFIG);
  private readonly _LOCAL_STORAGE_KEY = this._appConfig.appCode;

  private get _localStorageData(): { [key: string]: unknown } | null {
    return JSON.parse(localStorage.getItem(this._LOCAL_STORAGE_KEY));
  }

  private _storageData = new BehaviorSubject<{ [key: string]: unknown } | null>({});
  private _storageData$ = this._storageData.asObservable();

  constructor() {
    this._initStorage();
  }

  watch<T = unknown>(key: string): Observable<T> {
    return this._storageData$
      .pipe(
        map((data: { [k: string]: unknown } | null) => data[key] as T)
      );
  }

  private _initStorage = () => {
    this._storageData.next(this._localStorageData ?? {});
  }

  get<T = unknown>(key: string): T {
    return this._localStorageData && (this._localStorageData[key] as T);
  }

  set<T = unknown>({ key, value }: { key: string; value: T }): void {
    const updatedLocalStorageData = { ...(this._localStorageData ?? {}), [key]: value };
    localStorage.setItem(this._LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalStorageData));
  }

  clear(key: string): void {
    const { [key]: removedProp, ...remainingLocalStorageData } = this._localStorageData ?? {};
    localStorage.setItem(this._LOCAL_STORAGE_KEY, JSON.stringify(remainingLocalStorageData));
  }
}
