import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationModule } from '@tcc-mono/shared/authentication/passport';
import { IonicModule } from '@ionic/angular';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { environment } from '../environments/environment';
import { NativeModule } from './native.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AuthenticationModule.forRoot(),
    NativeModule
  ],
  providers: [
    getAppConfigProvider(environment)
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
