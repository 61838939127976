import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

@NgModule({
  imports: [CommonModule],
  providers: [
    InAppBrowser,
    Media,
    AndroidPermissions,
    Diagnostic
  ]
})
export class NativeModule { }
