import { GameSession } from "./game-session.model";

export class Team {
  id: string;
  name: string;
  activeGameSessionsCount?: number;
  remarks?: string;
  playersCount?: number;
  game_sessions: GameSession[];
  invitation_code?: string;
  url?: string;
  is_event?: boolean;
}
