import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader, TRANSLOCO_LOADER } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private _http: HttpClient) { }

  getTranslation(langPath: string) {
    return this._http.get<Translation>(`/assets/i18n/${langPath}.json`);
  }
}

export const availableLangs = ['nl', 'en'];

export const scopeLoader = (importer: any, root = 'i18n') => {
  return availableLangs.reduce((acc: any, lang) => {
    acc[lang] = () => importer(lang, root);
    return acc;
  }, {});
};

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader };

